import { ImportedAssetRules } from '@/server/types/importedAssetRules';
import { TAsset } from '@/store/AssetsStore';

export const IMPORTED_ASSETS_KEY = 'imported_assets';

export const getImportedAssetsFromLocalStorage = (): Record<string, TAsset[]> => {
  const localAssetsString = window.localStorage.getItem(IMPORTED_ASSETS_KEY);
  const localAssets: Record<string, TAsset[]> = {};

  if (!localAssetsString) {
    return localAssets;
  }

  try {
    const parsedAssets = JSON.parse(localAssetsString)

    Object.keys(parsedAssets).forEach((key: string) => {
      const validAssets = parsedAssets[key].filter((asset: TAsset) => {
        try {
          ImportedAssetRules.parse(asset);
          return true;
        } catch (error) {
          console.error(`Error parsing local asset ${asset}: ${error}`);
          return false;
        }
      });

      localAssets[key] = validAssets.map((asset: TAsset) => {
        asset.logoURI = 'images/default_coin.webp';
        return asset;
      });
    })
  } catch (error) {
    console.error('Error parsing local assets:', error);
  }

  return localAssets;
}
