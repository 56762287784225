import AssetsStore from '@/store/AssetsStore';
import UserStore from '@/store/UserStore';
import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';

export const useMarkFundedWallet = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { balances } = useSnapshot(AssetsStore.state);
    const { user } = useSnapshot(UserStore.state);

    const markUserAsFunded = useCallback(
        async function markUserAsFunded() {
            try {
                // await axios.post('/api/v1/private/user/mark_as_funded');
            } catch {
                // TODO: handle api error
                // console.log('Error marking user funded', error);
            } finally {
                setLoading(false);
            }
        },
        [user],
    );

    useEffect(() => {
        if (!balances || !user || user.is_funded) return;

        const isFunded = balances.some(balance => +balance.balance > 0);

        if (isFunded && !loading) {
            setLoading(true);
            markUserAsFunded();
        }
    }, [balances, user]);
};
