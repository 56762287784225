import { analytics, isUserPresentInDataLayer } from '@/helpers/analytics';
import { useCallback, useEffect } from 'react';

export const useAnalyticsInit = (smart_wallet_address: string, created_at?: Date) => {
    const updateAnalyticsInit = useCallback(async () => {
        if (!smart_wallet_address || isUserPresentInDataLayer(smart_wallet_address)) {
            return;
        }
        await analytics({
            type: 'init',
            params: {
                smart_wallet_address: smart_wallet_address,
            },
        });
        await analytics({
            type: 'userProperties',
            params: {
                propertyName: 'user_id',
                propertyValue: smart_wallet_address,
            },
        });
        await analytics({
            type: 'userProperties',
            params: {
                propertyName: 'smart_wallet_address',
                propertyValue: smart_wallet_address,
            },
        });
        await analytics({
            type: 'userProperties',
            params: {
                propertyName: 'created_at ',
                propertyValue: created_at,
            },
        });
    }, [smart_wallet_address, created_at]);

    useEffect(() => {
        updateAnalyticsInit();
    }, [updateAnalyticsInit]);
};