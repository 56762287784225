import LeaguesStore from '@/store/LeaguesStore';
import axios from 'axios';

export const getLeagues = async () => {
    LeaguesStore.setLeaguesLoading(true);

    const { data } = await axios.get('/api/v2/private/leagues');

    LeaguesStore.setLeagues(data);
};
