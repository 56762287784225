import AccountStore from '@/store/AccountStore';
import { getPaymasterConfigs } from '@/store/api/paymaster/getPaymasterAddresses';
import AssetsStore, { fetchAssets, fetchBalances } from '@/store/AssetsStore';
import SettingsStore from '@/store/SettingsStore';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

export const useGetAssetsAndConfigs = () => {
    const { assets, assetsLoading, balancesLoading } = useSnapshot(AssetsStore.state);
    const { activeChain } = useSnapshot(SettingsStore.state);
    const { accountAddress } = useSnapshot(AccountStore.state);

    useEffect(() => {
        if (!assets?.length && !assetsLoading) {
            fetchAssets();
            getPaymasterConfigs();
        }

        if (!balancesLoading && accountAddress && activeChain && !assetsLoading) {
            fetchBalances(accountAddress, activeChain);
        }
    }, [assets, accountAddress, activeChain]);
};
