import SettingsStore from '@/store/SettingsStore';
import axios from 'axios';

export const getPaymasterConfigs = async () => {
    const paymasterConfigsApiUrl = process.env.NEXT_PUBLIC_PAYMASTER_CONFIG_URL;

    try {
        const { data } = await axios.get(
            paymasterConfigsApiUrl?.replace('{chain_id}', String(SettingsStore.state.activeChain?.id)) ?? '',
        );
        const { litevault, paymaster, transactions } = data;

        litevault && SettingsStore.setLiteVaultContractAddress(litevault);

        paymaster?.[0] && SettingsStore.setPaymasterAddresses(paymaster?.[0]);

        transactions && SettingsStore.setTransactionConfigs(transactions);
    } catch (error) {
        console.log('Error while fetching paymaster addresses', error);
    }
};
