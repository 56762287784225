'use client';

import React, { ReactNode, useEffect } from 'react';

import { SessionRequestQueueProvider } from '@/context/sessionRequestQueue';
import { useAnalyticsInit } from '@/hooks/useAnalyticsInit';
import { useFetchLeaguesAndLeaderboard } from '@/hooks/Vault/useFetchLeaguesAndLeaderboard';
import { useGetAssetsAndConfigs } from '@/hooks/Vault/useGetAssetsAndConfigs';
import { useGetSeasons } from '@/hooks/Vault/useGetSeasons';
import { useMarkFundedWallet } from '@/hooks/Vault/useMarkFundedWallet';
import AccountStore from '@/store/AccountStore';
import SettingsStore from '@/store/SettingsStore';
import UserStore from '@/store/UserStore';
import dynamic from 'next/dynamic';
import { useSnapshot } from 'valtio';

const Privy = dynamic(() => import('@/providers/privy'));

interface Props {
    children: ReactNode;
}

export const InitSubscriptionProvider: React.FC<Props> = ({ children }: Props) => {
    const { user } = useSnapshot(UserStore.state);
    const { appReady } = useSnapshot(SettingsStore.state);
    const { accountAddress } = useSnapshot(AccountStore.state);

    useFetchLeaguesAndLeaderboard();

    useEffect(() => {
        const initializeWebApp = async () => {
            const webApp = (await import('@twa-dev/sdk'))?.default;

            if (typeof window !== 'undefined' && webApp && !appReady) {
                webApp?.ready();
            }
        };

        initializeWebApp();
    }, []);

    useGetSeasons();
    useGetAssetsAndConfigs();
    useMarkFundedWallet();

    useAnalyticsInit(accountAddress, user?.created_at);

    return (
        <Privy>
            <SessionRequestQueueProvider>{children}</SessionRequestQueueProvider>
        </Privy>
    );
};
