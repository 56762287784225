// src/contexts/SessionRequestQueueContext.tsx

import React, { FC, createContext, useContext, useMemo, useState } from 'react';
import { Web3WalletTypes } from '@walletconnect/web3wallet';

export interface TSessionRequestQueue {
    request: Web3WalletTypes.SessionRequest;
    status: string;
    successMessage?: string;
    error?: {
        code: string | number;
        message: string;
    };
}

interface TSessionRequestQueueContext {
    queue: TSessionRequestQueue[];
    enqueue: (request: TSessionRequestQueue) => void;
    dequeue: () => void;
    setQueue: React.Dispatch<React.SetStateAction<TSessionRequestQueue[]>>;
}

interface ISessionRequestQueueContext {
    children: React.ReactNode;
}

const SessionRequestQueueContext = createContext<TSessionRequestQueueContext | undefined>(undefined);

export const SessionRequestQueueProvider: FC<ISessionRequestQueueContext> = ({ children }) => {
    const [queue, setQueue] = useState<TSessionRequestQueue[]>([]);

    const enqueue = (request: TSessionRequestQueue) => {
        setQueue(prevQueue => {
            // Check if the item already exists in the queue by comparing ids
            const itemExists = prevQueue.some(item => item.request.id === request.request.id);

            // If the item doesn't exist, add it to the queue
            if (!itemExists) {
                return [...prevQueue, request];
            }

            // If the item already exists, return the previous queue unchanged
            return prevQueue;
        });
    };

    const dequeue = () => {
        setQueue(prevQueue => prevQueue.slice(1));
    };

    const value = useMemo(
        () => ({
            queue,
            enqueue,
            dequeue,
            setQueue,
        }),
        [queue],
    );

    return <SessionRequestQueueContext.Provider value={value}>{children}</SessionRequestQueueContext.Provider>;
};

export const useSessionRequestQueue = () => {
    const context = useContext(SessionRequestQueueContext);

    if (!context) {
        throw new Error('useSessionRequestQueue must be used within a SessionRequestQueueProvider');
    }

    return context;
};
