import { getSeasons } from '@/store/api/seasons/getSeasons';
import SeasonsStore from '@/store/SeasonsStore';
import { useEffect } from 'react';
import { useSnapshot } from 'valtio';

export const useGetSeasons = () => {
    const { seasonsConfigsLoading, seasonsConfigs } = useSnapshot(SeasonsStore.state);

    useEffect(() => {
        if (!seasonsConfigs && !seasonsConfigsLoading) {
            getSeasons();
        }
    }, [seasonsConfigs]);
};
