import AccountStore from '@/store/AccountStore';
import { getUserLeaderBoard } from '@/store/api/leaderboard';
import { getLeagues } from '@/store/api/leagues/getLeagues';
import LeaderboardStore from '@/store/LeaderboardStore';
import LeaguesStore, { ILeaguesState } from '@/store/LeaguesStore';
import SeasonsStore from '@/store/SeasonsStore';
import { useState, useEffect, useCallback } from 'react';
import { useSnapshot } from 'valtio';
interface UseFetchLeaguesAndLeaderboardState {
    loading: boolean;
    error: string | null;
    fetchLeaguesAndLeaderboard: () => Promise<void>;
}

export const useFetchLeaguesAndLeaderboard = (): UseFetchLeaguesAndLeaderboardState => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const { accountAddress } = useSnapshot(AccountStore.state);
    const { activeLeagues } = useSnapshot(LeaguesStore.state) as ILeaguesState;
    const { currentUserLeaderboard } = useSnapshot(LeaderboardStore.state);
    const { selectedSeason } = useSnapshot(SeasonsStore.state);

    const fetchLeaguesAndLeaderboard = useCallback(async () => {
        setLoading(true);
        setError(null);

        try {
            if (!activeLeagues?.length) {
                await getLeagues();
            }
            if (!currentUserLeaderboard && accountAddress && selectedSeason?.id) {
                await getUserLeaderBoard(selectedSeason?.id);
            }
        } catch (err) {
            console.error('Failed to fetch data:', err);
            setError('Failed to fetch leagues or leaderboard data');
        } finally {
            setLoading(false);
        }
    }, [activeLeagues, selectedSeason, currentUserLeaderboard, accountAddress, getLeagues, getUserLeaderBoard]);

    useEffect(() => {
        fetchLeaguesAndLeaderboard();
    }, [fetchLeaguesAndLeaderboard]);

    return { loading, error, fetchLeaguesAndLeaderboard };
};
