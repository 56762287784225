import { z } from 'zod';

// Custom validation functions
export const tokenAddressValidator = z.string().regex(/^0x[a-fA-F0-9]{40}$/, 'Invalid token address');
export const symbolValidator = z.string().regex(/^[A-Z]{1,5}$/, 'Invalid symbol');
export const nameValidator = z.string().regex(/^[a-zA-Z0-9 ]{1,30}$/, 'Invalid name');
export const decimalValidator = z.union([z.string().regex(/^\d+$/, 'Invalid decimal'), z.number()]).refine(
    decimal => {
        const parsed = typeof decimal === 'string' ? parseInt(decimal, 10) : decimal;

        return parsed <= 18;
    },
    { message: 'Decimal must be less than or equal to 18' },
);

// Schema for asset rules using custom validators
export const ImportedAssetRules = z.object({
    name: nameValidator,
    address: tokenAddressValidator,
    symbol: symbolValidator,
    decimals: decimalValidator,
});
