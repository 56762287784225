import { proxy } from 'valtio';

export interface TLeaderboardUser {
    id?: number;
    smart_wallet_address: string;
    league_id: number;
    subleauge_id?: number;
    league_position: number;
    total_score: number;
    nav_score?: number;
    quests_score?: number;
    daily_claim_score?: number;
    staking_score?: number;
    trading_volume_score?: number;
    referral_points_score?: number;
    rewards_allocation?: number;
    created_at?: string;
    updated_at?: string;
}

export interface TLeaderboard {
    users?: TLeaderboardUser[] | null;
    currentUserLeaderboard?: TLeaderboardUser | null;
    // It can be top 5 of current league or next league
    nextLeagueUsers?: TLeaderboardUser[] | null;
    currentLeagueId?: number;
}

const state = proxy<TLeaderboard>({
    users: null,
    currentUserLeaderboard: null,
    nextLeagueUsers: null,
    currentLeagueId: -1,
});

const LeaderboardStore = {
    state,
    setLeaderboard(payload: TLeaderboard) {
        state.users = payload.users;
        state.currentLeagueId = payload.currentLeagueId;
    },
    setCurrentUserLeaderboard(payload: TLeaderboardUser | null) {
        state.currentUserLeaderboard = payload;
    },
    setNextLeagueUsers(payload: TLeaderboardUser[]) {
        state.nextLeagueUsers = payload;
    },
    setLeaderBoardLeagueId(payload: number) {
        state.currentLeagueId = payload;
    },
    resetLeaderboard() {
        state.users = null;
        state.currentUserLeaderboard = null;
        state.nextLeagueUsers = null;
        state.currentLeagueId = -1;
    },
};

export default LeaderboardStore;
