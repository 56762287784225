import { proxy } from 'valtio';

export interface TLeague {
    id: string;
    name: string;
    image: string;
    min_score: number;
    size: number;
    status: boolean;
    created_at: string;
}

export interface ILeaguesState {
    leagues: TLeague[] | null;
    leaguesLoading: boolean;
    activeLeagues: TLeague[] | null;
}

const state = proxy<ILeaguesState>({
    leagues: null,
    activeLeagues: null,
    leaguesLoading: false,
});

const LeaguesStore = {
    state,
    setLeagues(payload: TLeague[] | null) {
        state.leagues = payload;
        state.activeLeagues = payload?.filter((league) => league.status) ?? null;
        state.leaguesLoading = false;
    },
    setLeaguesLoading(payload: boolean) {
        state.leaguesLoading = payload;
    },
};

export default LeaguesStore;
