import LeaderboardStore from '@/store/LeaderboardStore';
import axios from 'axios';

export const getUserLeaderBoard = async (season_id?: number) => {
    const params: Record<string, any> = {};

    if (season_id !== undefined) {
        params.season_id = season_id;
    }

    const { data } = await axios.get('/api/v2/private/leaderboard/me', {
        params,
        withCredentials: true,
    });

    LeaderboardStore.setCurrentUserLeaderboard(data?.me);
};

export const getLeaderboardByLeagueId = async (leagueId: number, season_id?: number) => {
    const params: Record<string, any> = {};

    if (season_id !== undefined) {
        params.season_id = season_id;
    }

    const { data } = await axios.get('/api/v3/public/leaderboard', {
        params: {
            ...params,
            id: leagueId,
            limit: 30,
        },
    });

    LeaderboardStore.setLeaderboard({
        users: data,
        currentLeagueId: leagueId,
    });
};

// DEPRECATED
export const getLeaderboardBySubleagueId = async (subleagueId: number, currentLeagueId: number, season_id?: number) => {
    const params: Record<string, any> = {};

    if (season_id !== undefined) {
        params.season_id = season_id;
    }

    const { data } = await axios.get('/api/v1/private/user/leaderboard/by_subleague', {
        params: { ...params, subleague_id: subleagueId, league_id: currentLeagueId },
    });

    LeaderboardStore.setLeaderboard({
        users: data,
        currentLeagueId,
    });
};

export const getNextLeagueLeaderboard = async (nextLeagueId: number, limit: number = 5, season_id?: number) => {
    const params: Record<string, any> = {};

    if (season_id !== undefined) {
        params.season_id = season_id;
    }

    const { data } = await axios.get('/api/v3/public/leaderboard', {
        params: {
            ...params,
            id: nextLeagueId,
            limit: limit,
            is_tail: true,
        },
    });

    LeaderboardStore.setNextLeagueUsers(data);
};

export const getLeaderboardByAddress = async (currentLeagueId: number, season_id?: number) => {
    const params: Record<string, any> = {};

    if (season_id !== undefined) {
        params.season_id = season_id;
    }

    const { data: fData } = await axios.get('/api/v2/private/leaderboard/me', {
        params: {
            ...params,
            batch: 5,
        },
        withCredentials: true,
    });

    LeaderboardStore.setLeaderboard({
        users: fData.batch,
        currentLeagueId: currentLeagueId,
    });

    const { data: sData } = await axios.get('/api/v3/public/leaderboard', {
        params: {
            ...params,
            id: currentLeagueId + 1,
            limit: 3,
            is_tail: true,
        },
    });

    LeaderboardStore.setNextLeagueUsers(sData);
};
