import SeasonsStore, { ISeasonsConfigs } from '@/store/SeasonsStore';
import axios from 'axios';

export const getSeasons = async () => {
    SeasonsStore.setSeasonsConfigsLoading(true);

    try {
        const seasonsConfigs: ISeasonsConfigs = (await axios('/api/v3/public/seasons', { method: 'GET' }))?.data;

        seasonsConfigs?.seasons?.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime());

        const activeSeason = seasonsConfigs?.seasons?.find((season) => season.id === seasonsConfigs.recent_season_id);

        const upcomingSeason = seasonsConfigs?.seasons?.find(
            (season) => new Date(season.start_date) > new Date(activeSeason?.end_date || ''),
        );

        seasonsConfigs?.seasons?.sort((a, b) => a.id - b.id);

        SeasonsStore.setRecentSeason(activeSeason);
        SeasonsStore.setSelectedSeason(activeSeason);
        SeasonsStore.setSeasonsConfigs(seasonsConfigs);

        if (upcomingSeason) {
            SeasonsStore.setUpcomingSeason({
                ...upcomingSeason,
                status: 'upcoming',
            });
        }
    } catch (error) {
        console.error('Error fetching balances:', error);
    } finally {
        SeasonsStore.setSeasonsConfigsLoading(false);
    }
};
